import './app/app.css';
import { Provider } from 'react-redux';
import Layout from './app/Layout';
import { store } from './app/store';

export default function App() {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
}
