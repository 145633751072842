import { ReportConfig } from "./helpers";

export interface IPavingRow {
  chainage: number;
  levels: number[];
}

export interface IPavingData {
  rows: IPavingRow[];
}

export class PavingData implements IPavingData {
  rows: IPavingRow[];

  constructor(pavingData: IPavingData) {
    this.rows = pavingData.rows;
  }
  
  getChainage(index: number): number | null {
    return index >= 0 && index < this.rows.length ? this.rows[index].chainage : null;
  }
  
  getLevels(chainage: number): number[] | undefined {
    return this.rows.find((row) => row.chainage === chainage)?.levels;
  }
  
  getLevel(chainage: number, column: number): number | undefined {
    const levels = this.getLevels(chainage);
    return levels && levels[column];
  }

  getRuns(): number[] {
    if (!this.rows) {
      return [];
    } 
    
    const runs: number[] = [];
    for (let i = 0; i < this.rows[0].levels.length - 1; ++i) {
      runs.push(i + 1);
    }
    
    return runs;
  }
  
  public static async fromFileAsync(file: File): Promise<PavingData> {
    const text = await file.text();
    const rows = text.split(/\r?\n/);

    // parse each line of text and then sort the rows by chainage in ascending order
    const pavingRows = rows.filter(r => !!r && r.length > 1).map(parsePavingRow).sort((a, b) => a.chainage - b.chainage);
    //const isIncreasing = pavingRows[0].chainage < pavingRows[pavingRows.length - 1].chainage;
    
    return new PavingData({
      rows: pavingRows
    });
  }
  
  public static async fromFilesAsync(files: File[]): Promise<PavingData[]> {
    return await Promise.all(files.map((file) => PavingData.fromFileAsync(file)));
  }
  
  public static processMultipleFiles(filesData: PavingData[], config: ReportConfig): PavingData {
    const rows: IPavingRow[] = [];
    
    // combine the multiple PavingData objects into a single PavingData
    
    // 1) sort the files by the first chainage in each file, in ascending order
    const sortedFilesData = filesData.sort((a, b) => a.rows[0].chainage - b.rows[0].chainage);
    
    // 2) loop over the rows in each file and add them to the rows array
    //    -> checking for duplicate chainages and overlaps
    for (const fileData of sortedFilesData) {
      for (const row of fileData.rows) {
        const existingRow = rows.find((r) => r.chainage === row.chainage);
        
        // 3) check for duplicate chainages
        if (existingRow) {
          //existingRow.levels = row.levels;
          // ignore duplicates...
        } else {
          // 4) check for overlaps, esp. if we're NOT using a chainageInterval config
          rows.push(row);
        }
      }
    }
    
    return new PavingData({
      rows: rows.sort((a, b) => a.chainage - b.chainage)
    });
  }
}

function parsePavingRow(row: string): IPavingRow {
  const [chainage, ...levels] = row.split(',').map(Number);
  return { chainage, levels };
}
