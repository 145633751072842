import { createAsyncThunk } from "@reduxjs/toolkit";
import { DipData } from "../../../utils/reports/dipData";
import { PavingData } from "../../../utils/reports/pavingData";
import { ReportData } from "../../../utils/reports/reportData";
import type { IReportComponents } from "./reportSlice";
import { ReportActions } from "./reportSlice";
import type { AppThunkApi } from '../../../app/store';
import { batch } from "react-redux";
import { IClient } from "../../../utils/reports/helpers";

export const processReportFiles = createAsyncThunk(
  'reports/processReportFiles',
  async (_: void, thunkApi) => {
    const { dispatch, getState } = thunkApi as AppThunkApi;
    const { reportFiles, dipFiles, pavingFiles, reportConfig } = getState().reports;
    
    if (!reportConfig) {
      console.error('No report config found');
      
      return false;
    }
    
    try {
      let dips: DipData | null = null;
      let paving: PavingData | null = null;
      let report: ReportData | null = null;
      let multiPaving: PavingData[] | null = null;
      
      // 1) read in all the files and do initial processing
      if (dipFiles && dipFiles.length > 0) {
        dips = await DipData.fromFilesAsync(dipFiles);
      }

      if (pavingFiles) {
        if (pavingFiles.length === 1) {
          paving = await PavingData.fromFileAsync(pavingFiles[0]);
        } else if (pavingFiles.length > 1) {
          multiPaving = await PavingData.fromFilesAsync(pavingFiles);
        }
      }

      if (reportFiles && reportFiles.length > 0) {
        report = await ReportData.fromFilesAsync(reportFiles);
      }

      // 2) check for invalid data and invalid combinations of data (e.g. no dips and no report)
      if (!dips && !report) {
        return false;
      }

      if (!dips && !!paving && !!multiPaving) {
        return false;
      }

      // 3) do any extended priocessing on the data according to the report config 
      if (!paving && !!multiPaving) {
        paving = PavingData.processMultipleFiles(multiPaving, reportConfig);
      }
      
      // process dips AFTER processing multiple paving files (if required)
      //  - this ensures the paving object is created from the multiPaving object
      if (dips && paving) {
        // calculate expected dip values if we have dip & paving data
        dips.process(paving, reportConfig);
      }
      
      // 4) dispatch state updates
      batch(() => {
        dispatch(ReportActions.setReportComponents({ dips, paving, report } as IReportComponents));
        dispatch(ReportActions.selectRun(null));
        dispatch(ReportActions.setDownloadedReportId(null));
      })
      
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
);

export const ProcessReportFilesActions = {
  pending: processReportFiles.pending,
  fulfilled: processReportFiles.fulfilled,
  rejected: processReportFiles.rejected,
};

export const queryClients = createAsyncThunk(
  'reports/queryClients',
  async (_: void, thunkApi) => {
    try {

      const resp = await fetch('/api/queryClients', {
        method: 'get',      
      });
      
      if (resp.status !== 200) {
        console.error('failed querying clients list from API backend');
        console.error(resp.statusText);
        return;
      }
      
      var clients = await resp.json() as IClient[];
      if (!clients) {
        console.error('failed parsing clients list JSON');
        return;
      }
      
      thunkApi.dispatch(ReportActions.setClients(clients));
    }
    catch (e) {
      console.error(e);
    }
  }
);

export const ReportThunks = {
  queryClients,
  processReportFiles
}