import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';

type LoaderType = 'circle' | 'chase' | 'grid' | 'material';

export type LoaderProps = {
  type?: LoaderType;
  centered?: boolean;
  color?: string;
  size?: number;
  text?: string;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  delayed?: boolean;

  // material type props
  strokeWidth?: string | number;
};

const WrapperDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'color',
})((props: { size: number; color: string }) => ({
  width: props.size,
  height: props.size,
}));

const InnerDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})((props: { color: string }) => ({
  '&::before': {
    backgroundColor: `${props.color}!important`,
  },
}));

const GridInnerDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})((props: { color: string }) => ({
  backgroundColor: props.color,
}));

/**
 * Loader component for displaying various different spinners (from spinkit css)
 * @param props the props for the loader (initialized to loaderDefaults if no props are passed in)
 */
function Loader(props: LoaderProps): ReactElement {
  // if props is not null, then merge props with loaderDefaults, overwriting any defaults contained in props
  const {
    type = 'circle',
    centered = true,
    color = '#1565c0',
    size = 40,
    text = '',
    style = {},
    wrapperStyle = {},
    delayed = false,

    strokeWidth
  } = {
    ...props,
  };

  const centerClass = centered ? 'sk-center' : '';

  const ref = useRef<HTMLDivElement | null>(null);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    if (delayed) {
      console.log('delayed loader');
      setDisplay(false);
      const timeout = setTimeout(() => {
        clearTimeout(timeout);

        if (ref.current) {
          setDisplay(true);
        }
      }, 250);
    }

    return () => {
      ref.current = null;
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const circle = () => (
    <WrapperDiv
      size={size}
      color={color}
      className={`${centerClass} sk-circle`}>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-circle-dot'></InnerDiv>
    </WrapperDiv>
  );

  const chase = () => (
    <WrapperDiv
      size={size}
      color={color}
      className={`${centerClass} sk-chase`}>
      <InnerDiv
        color={color}
        className='sk-chase-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-chase-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-chase-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-chase-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-chase-dot'></InnerDiv>
      <InnerDiv
        color={color}
        className='sk-chase-dot'></InnerDiv>
    </WrapperDiv>
  );

  const grid = () => (
    <WrapperDiv
      size={size}
      color={color}
      className={`${centerClass} sk-grid`}>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
      <GridInnerDiv
        color={color}
        className='sk-grid-cube'></GridInnerDiv>
    </WrapperDiv>
  );

  const material = () => {
    return (
      <WrapperDiv size={size} color={color} className={`${centerClass}`}>
        <CircularProgress size={size} sx={{
          color: color,
          '&.MuiCircularProgress-root .MuiCircularProgress-circle': {
            strokeWidth: strokeWidth ?? ''
          }
        }} />
      </WrapperDiv>
    )  
  };
  
  const getSpinner = () => {
    switch (type) {
      case 'circle':
        return circle();
      case 'chase':
        return chase();
      case 'grid':
        return grid();
      case 'material':
        return material();
      default:
        return circle();
    }
  };

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...wrapperStyle,
      }}>
      {display && (
        <div
          style={{
            ...style,
          }}>
          <Grid height='100%' container direction='row'>
            <Grid item container xs={12}>
              {getSpinner()}
            </Grid>

            {text && (
              <Grid
                style={{ marginTop: '2rem' }}
                item
                container
                justifyContent='center'
                alignItems='center'
                xs={12}>
                <label>{text}</label>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Loader;

export const ThinMaterialLoader = (props: Omit<LoaderProps, 'type' | 'strokeWidth'>) => (
  <Loader type='material' strokeWidth={1} {...props} />
);
