import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './app/theme';
import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import './utils/LINQ';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXmJWf1FpTGpQdk5yd19DaVZUTX1dQl9hSXlTckVmXHtfcHNVRGM=');

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const ReportUploader = React.lazy(() => import('./features/reports/uploaderPage/ReportUploader'));
const ReportViewer = React.lazy(() => import('./features/reports/reportViewer/ReportViewer'));
const DipGridSyncfusion = React.lazy(() => import('./features/reports/reportViewer/DipGridSyncfusion'));
const PavingChart = React.lazy(() => import('./features/reports/reportViewer/PavingChart'));
const DownloadPage = React.lazy(() => import('./features/reports/downloadPage/DownloadPage'));

const Root = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    loader: ({ request }) => {
      const url = new URL(request.url);
      const path = url.pathname;
      if (path.length <= 1) {
        return redirect('/uploader');
      }
      return null;
    },
    children: [
      {
        path: 'uploader',
        element: <ReportUploader />,
        loader: () => {
          console.log('uploader page loader...');
          return null;
        },
      },
      {
        path: 'viewer',
        element: <ReportViewer />,
        children: [
          {
            path: 'dipsheet',
            element: <DipGridSyncfusion />,
          },
          {
            path: 'paving',
            element: <PavingChart />,
          },
          {
            path: '*',
            element: <Navigate to='dipsheet' replace />,
          },
        ],
        loader: ({ request }) => {
          const url = new URL(request.url);
          const path = url.pathname;
          if ((path.match(/\//gi)?.length || 0) < 2) {
            return redirect('/viewer/dipsheet');
          }
          return null;
        },
      },
      {
        path: 'download',
        element: <DownloadPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to='/uploader' replace />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
