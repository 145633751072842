import React, { ReactNode, Suspense, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import TimelineIcon from '@mui/icons-material/Timeline';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  Outlet,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom';
import { useEffect } from 'react';
import Loader from '../components/Loader';

const drawerWidth = 240;

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  disabled?: boolean;
  isActive: boolean;

  closeDrawer: () => void;
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, closeDrawer, isActive, disabled = false } = props;

  return (
    <ListItem disablePadding component={Link} to={to}>
      <ListItemButton
        selected={isActive}
        onClick={closeDrawer}
        disabled={disabled}
        sx={{
          '& Mui-selected': {
            border: 'red 1px solid',
          },
        }}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText color='#0000007F' primary={primary} />
      </ListItemButton>
    </ListItem>
  );
}

// const ToolbarButton = styled(Button)(({ theme }) => ({
//   color: 'inherit',
//   fontFamily: 'Montserrat',
//   fontSize: 'medium',
//   '&:hover': {
//     fontWeight: 600,
//   },
// }));

function Layout({ children }: { children?: ReactNode }) {
  const location = useLocation();
  const path = location.pathname.slice(1);
  
  const hasInit = useRef(false);
  const [flyoutDrawerOpen, setFlyoutDrawerOpen] = useState(false);

  //const dispatch = useAppDispatch();

  const handleDrawerToggle = () => {
    setFlyoutDrawerOpen(!flyoutDrawerOpen);
  };

  // subscribe to MSAL events to handle user login/logout
  useEffect(() => {
    // use hasInit ref to ensure we only add the callback once
    if (!hasInit.current) {
      hasInit.current = true;     
    }

    return () => {
      
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawer = useMemo(
    () => {
      const isActive = (route: string): boolean => {
        return location.pathname.endsWith(route);
      };

      const closeDrawer = () => {
        if (flyoutDrawerOpen) {
          setFlyoutDrawerOpen(false);
        }
      };

      return (
        <div>
          {/* use empty toolbar to add paddding for drawer elements */}
          <Toolbar />
          <List>
            <ListItemLink
              to='/uploader'
              isActive={isActive('/uploader')}
              primary='Uploader'
              closeDrawer={closeDrawer}
              icon={<CloudUploadOutlinedIcon />}
            />

            <Divider sx={{ mt: '32px', mb: 0 }} />

            {/* <ListItemLink
              to='/history'
              isActive={isActive('/history')}
              primary='History'
              closeDrawer={closeDrawer}
              disabled={!isAuthenticated}
              icon={<HistoryIcon />}
            /> */}

            <ListItemLink
              to='/viewer'
              isActive={isActive('/viewer')}
              primary='Viewer'
              closeDrawer={closeDrawer}
              icon={<TimelineIcon />}
            />
          </List>

          {/* <Button
            component={RouterLink}
            to='/account'
            disabled={!isAuthenticated}
            onClick={closeDrawer}
            fullWidth={true}
            variant='contained'
            disableElevation={true}
            color='primary'
            sx={{
              width: 240,
              position: 'fixed',
              display: 'block',
              bottom: 0,
              left: 0,
              height: 100,
              borderRadius: 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}>
            <AccountCircleOutlinedIcon
              style={{
                fontSize: 48,
                left: 'calc(50% - 24px)',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 8,
              }}
            />
            <Typography
              variant='body1'
              align='center'
              style={{
                textTransform: 'none',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 130,
                marginTop: 8,
                color: isAuthenticated ? 'white' : 'gray',
                backgroundColor: 'transparent',
              }}>
              Account
            </Typography>
          </Button> */}
        </div>
      );
    },
    // flyout/location deps are required for the isActive/closeDrawer callback functions
    //  -> we use the underlying deps here instead of having to use useCallback() for each callback
    [flyoutDrawerOpen, location.pathname]
  );

  return (
    <Box>
      <AppBar color='primary' position='sticky' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}>
            {flyoutDrawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            Paveset Report Viewer
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label='report viewer pages'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant='temporary'
          open={flyoutDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}>
          {drawer}
        </Drawer>

        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 2,
          ml: { xs: 0, lg: `${drawerWidth}px` },
          width: { xs: '100%', lg: `calc(100% - ${drawerWidth}px)` },
        }}>
        {/* add child pages (react-router routes) inside the main container */}
        {/* {children} */}
        <Suspense
          fallback={
            <Loader
              type='material'
              strokeWidth={2}
              size={64}
              centered
              color='#f50057'
              wrapperStyle={{ height: '400px' }}
              text={`loading ${path} page...`}
            />
          }>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
}

export default Layout;
