import { IDipData } from "./dipData";
import { IPavingData } from "./pavingData";
import { IReportData } from "./reportData";

export type FileType = 'REPORT' | 'DIP' | 'PAVING' | 'UNKNOWN';

export function getFileType(file: File): FileType {
  if (file.name.endsWith('.dip')) {
    return 'DIP';
  } else if (file.name.endsWith('.rpt')) {
    return 'REPORT';
  } else if (file.name.endsWith('.csv')) {
    return 'PAVING';
  } else {
    return 'UNKNOWN';
  }
}

export function isDipFile(file: File): boolean {
  return getFileType(file) === 'DIP';
}

export function isReportFile(file: File): boolean {
  return getFileType(file) === 'REPORT';
}

export function isPavingFile(file: File): boolean {
  return getFileType(file) === 'PAVING';
}

export const LayerValues = ['Layer 1', 'Layer 2', 'Layer 3', 'Wearing Course', 'Corrector', 'Base Layer'] as const;
type LayersTuple = typeof LayerValues;
export type Layer = LayersTuple[number];

//export type Layers = 'Layer 1' | 'Layer 2' | 'Layer 3' | 'Wearing Course' | 'Corrector' | 'Base Layer';
export interface IClient {
  company: string;  
  
  icon?: string;
  banner?: string;
}

export type ReportConfig = {
  compaction: number;

  title: string;
  
  client: IClient | null;
  date: Date | null;
  layer: Layer;

  chainageInterval?: number;

  tolerance: {
    upper?: number;
    lower?: number;
  }
}

export const defaultConfig: ReportConfig = {
  compaction: 25,
  // client: {
  //   company: 'Paveset',
  //   icon: 'paveset/PavesetLogoTransparent_x256.png',
  //   banner: 'paveset/Paveset_Banner.png'
  // },
  title: '',
  client: null,
  date: null,
  layer: 'Wearing Course',

  tolerance: {
    upper: 10,
    lower: -10
  }
};

export function applyCompaction(level: number | undefined, compaction: number): number | undefined {
  return level && Math.round(level * (1 + compaction / 100));
}

export interface IReportBundle {
  config: ReportConfig;
  paving?: IPavingData;
  report?: IReportData;
  dips?: IDipData;
}

export type StoreReportResult = {
    id: string;
    success: boolean;
  };
  
export const getClientImageUrl = (icon?: string): string => {
  return icon ? `https://pavesetshared.blob.core.windows.net/clients/${icon}` : '';
};