import { parse } from 'date-fns'


export interface IReportRun {
  run: number;
  entries: IReportEntry[];
}

enum ReportEntryType {
  'START' = 'START',
  'STOP' = 'STOP',
  'MARK' = 'MARK',
  'TEMP' = 'TEMP',
  'N0-N0' = 'N0-N0',
  'N0-N1' = 'N0-N1',
  'N1-N0' = 'N1-N0',
  'N1-N1' = 'N1-N1',
}

export interface IReportEntry {
  timestamp: Date;
  type: ReportEntryType;

  chainage: number;
  run: number;
  temperature: number;
  speed: number;
}

export interface IReportData {
  runs: IReportRun[];
}

export class ReportData implements IReportData {
  runs: IReportRun[];

  constructor(reportData: IReportData) {
    this.runs = reportData.runs;
  }

  getRun(run: number): IReportEntry[] {
    return this.runs.find(r => r.run === run)?.entries ?? [];
  }
  
  combineWith(other: ReportData): ReportData {
    const runs = [...this.runs, ...other.runs].sort((a, b) => a.run - b.run);
    
    return new ReportData({
      runs
    });
  }
  
  private static combine(reports: ReportData[]): ReportData {
    const allRuns = reports.flatMap((report) => report.runs).sort((a, b) => a.run - b.run);
    
    return new ReportData({
      runs: allRuns
    });
  }
  
  public static async fromFilesAsync(files: File[]): Promise<ReportData> {
    const reports = await Promise.all(files.map((file) => ReportData.fromFileAsync(file)));
    
    // combine all reports together
    return this.combine(reports);
  }
  
  public static async fromFileAsync(file: File): Promise<ReportData> {
    const text = await file.text();
    const lines = text.split(/\r?\r\n?\n/);
    const runs: IReportRun[] = [];

    const entries: IReportEntry[] = [];
    for (const line of lines) {    
      if (this.isDataLine(line)) {
        // is valid data line
        const entry = this.parseDataLine(line);
        if (entry) {
          entries.push(entry);
        }
      }
    }

    entries.sort((a, b) => a.run - b.run);
    
    for (const [run, runEntries] of entries.groupBy((e) => e.run).entries()) {
      runs.push({
        run,
        entries: this.calculate(this.trim(runEntries))
      });
    }

    return new ReportData({
      runs: ReportData.filterRuns(runs)
    });
  }

  private static filterRuns(runs: IReportRun[]): IReportRun[] {
    const trimmed: IReportRun[] = [];
    for (const run of runs) {
      if (!run.entries || run.entries.length > 5) {
        trimmed.push({
          ...run,
          entries: run.entries
        });
      }
    }
    
    return trimmed;  
  }
  
  private static calculate(entries: IReportEntry[]): IReportEntry[] {
    const calc: IReportEntry[] = [];
    
    calc.push(entries[0]);
    for (let i = 1; i < entries.length; i++) {
      calc.push({
        ...entries[i],
        speed: Math.abs(entries[i].chainage - entries[i - 1].chainage) / (entries[i].timestamp.getTime() - entries[i - 1].timestamp.getTime()) * 1000 * 60
      })
    }
    
    return calc;
  }
  
  private static trim(entries: IReportEntry[]): IReportEntry[] {
    const trimmed: IReportEntry[] = [];
    let foundStart = false;
    let startIndex = 0;
    for (let i = 0; i < entries.length; ++i) {
      const prev = i > 0 ? entries[i - 1] : null;
      const e = entries[i];
      if (!foundStart) {
        if (e.type === ReportEntryType['N0-N1'] || 
            e.type === ReportEntryType['N1-N1'] || 
            e.type === ReportEntryType['N1-N0']) {
          foundStart = true;
          startIndex = i;
          trimmed.push(e);
        }
      } else {
        if (e.chainage === prev?.chainage) {
          trimmed.length -= 1;
        }
        
        trimmed.push(e);
        
        if (e.type === ReportEntryType['N0-N0']) {
          // if the run is really short, ignore it and try and find more
          if (i - startIndex < 5) {
            foundStart = false;
            startIndex = -1;
            trimmed.length = 0;
            continue;
          }
          break;
        }
      }
    }
    
    if (trimmed.length <= 0) {
      return [];
    }
    
    for (let i = 1; i < trimmed.length; ++i) {
      const prev = trimmed[i - 1];
      const e = trimmed[i];
      
      if (e.timestamp.getTime() === prev.timestamp.getTime()) {
        // remove current entry
        trimmed.splice(i, 1);
        --i;
      }
    }
    return trimmed;
  }
  
  private static isDataLine(line: string): boolean {
    const match = line.match(/,/g);
    if (!match) {
      return false;
    }
    
    return match.length >= 6;
  }

  private static parseDataLine(line: string): IReportEntry | null {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [date, time, chainageText, runText, tempText, UNKNOWN, typeText, ...rest] = line.split(',');
    if (!(typeText in ReportEntryType)) {
      return null;
    }

    const type = ReportEntryType[typeText as keyof typeof ReportEntryType];
    const timestamp = parse(`${date} ${time}`, "dd-MM-yy HH:mm:ss", new Date());
    const chainage = Number.parseFloat(chainageText);
    const run = Number.parseInt(runText);
    const temperature = Number.parseFloat(tempText);

    return {
      timestamp,
      chainage,
      run,
      temperature,
      speed: 0, // set to 0 now so we can calculate actual speed later
      type
    };
  }
}
