import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { DipData } from "../../../utils/reports/dipData";
import { defaultConfig, IClient, isDipFile, isPavingFile, isReportFile, ReportConfig } from "../../../utils/reports/helpers";
import { PavingData } from "../../../utils/reports/pavingData";
import { ReportData } from "../../../utils/reports/reportData";
import { processReportFiles, ProcessReportFilesActions, queryClients } from "./reportActions";

export interface ReportState {
  reportFiles: File[] | null;
  dipFiles: File[] | null;
  pavingFiles: File[] | null;
  
  reportConfig: ReportConfig;
  reportComponents: IReportComponents | null;
  
  selectedRun: number | null;
  
  clients?: IClient[];
  clientsState: 'idle' | 'loading' | 'failed';
  
  downloadedReportId?: string;
}

const initialState: ReportState = {
  reportFiles: null,
  dipFiles: null,
  pavingFiles: null,
  
  reportConfig: defaultConfig,
  reportComponents: null,
  
  selectedRun: null,
  clientsState: 'idle',
};

export interface IReportComponents {
  dips?: DipData;
  paving?: PavingData;
  report?: ReportData;
}

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<File[]>) => { 
      if (action.payload.length > 0) {
        state.reportFiles = action.payload.filter(f => f.name.toLowerCase().endsWith('.rpt'));
        state.dipFiles = action.payload.filter(f => f.name.toLowerCase().endsWith('.dip'));
        state.pavingFiles = action.payload.filter(f => f.name.toLowerCase().endsWith('.csv'));
      }
    },
    
    removeFile: (state, action: PayloadAction<File>) => {
      if (isReportFile(action.payload) && state.reportFiles && state.reportFiles.length > 0) {
        state.reportFiles = state.reportFiles.filter(f => f.name !== action.payload.name);
      }
      else if (isDipFile(action.payload) && state.dipFiles && state.dipFiles.length > 0) {
        state.dipFiles = state.dipFiles.filter(f => f.name !== action.payload.name);
      }    
      else if (isPavingFile(action.payload) && state.pavingFiles && state.pavingFiles.length > 0) {
        state.pavingFiles = state.pavingFiles.filter(f => f.name !== action.payload.name);
      }
    },
    
    clearFiles: (state) => { 
      state.reportFiles = state.dipFiles = state.pavingFiles = null;
    },
    
    setReportConfig: (state, action: PayloadAction<ReportConfig>) => {
      state.reportConfig = action.payload;
    },
    
    setReportComponents: (state, action: PayloadAction<IReportComponents>) => {
      state.reportComponents = action.payload;
    },
    
    selectRun: (state, action: PayloadAction<number | null>) => {
      state.selectedRun = action.payload;
    },
    
    setClients: (state, action: PayloadAction<IClient[]>) => { 
      state.clients = action.payload;
    },
    
    setDownloadedReportId: (state, action: PayloadAction<string | null>) => {
      state.downloadedReportId = action.payload ?? undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(processReportFiles.pending, (state, action) => {
      console.log('processReportFiles pending...');
    });
    
    builder.addCase(processReportFiles.fulfilled, (state, action) => {
      console.log('processReportFiles fulfilled...');
    });
    
    builder.addCase(processReportFiles.rejected, (state, action) => {
      console.log('processReportFiles rejected...');
    });
    
    builder.addCase(queryClients.pending, (state, action) => {
      state.clientsState = 'loading';
    });
    
    builder.addCase(queryClients.fulfilled, (state, action) => {
      state.clientsState = 'idle';
    });
  }
});

export type ReportActionTypes = typeof reportSlice.actions;

export const ReportActions = {
  ...reportSlice.actions,
  processReportFiles: {
    ...ProcessReportFilesActions
  },
};

export const selectValidSetup = (state: RootState) => {
  const { dipFiles, reportFiles, pavingFiles, reportConfig } = state.reports;
  const {
    title,
    client,
    date
  } = reportConfig;
  
  if (!title || title.length === 0 || !client || !date) {
    return false;
  }
  
  if (!!dipFiles && dipFiles.length > 0) {
    return !!pavingFiles && pavingFiles.length > 0;
  }
  
  return !!reportFiles && reportFiles.length > 0;
}

const reportReducer = reportSlice.reducer;
export default reportReducer;