import { applyCompaction, ReportConfig } from "./helpers";
import { PavingData } from "./pavingData";

export interface IDipEntry {
  /**
   * Indicates if this entry is a 'new style' entry (with middle dip & timestamp) or 'old style'
   */
  isNew: boolean;
  
  chainage: number;
  run: number;
  lhs: IDip | null;
  rhs: IDip | null;

  description: string | null;

  // new values
  middle: IDip | null;
  timestamp: Date | null;

  // probably just remove this in the future. But keep it as a placeholder in the CSV structure
  picture: string | null;
}

export enum DipType {
  'Paveset' = 'Paveset',
  'Manual' = 'Manual',
  'Match' = 'Match',
  'Middle' = 'Middle',
  'Empty' = 'Empty'
}

export interface IDip {
  type: DipType;
  value: number | null;
  
  expected?: number;
}

export interface IDipRun {
  run: number;
  dips: IDipEntry[];
}

export interface IDipData {
  runs: IDipRun[];
}

export class DipData implements IDipData {
  runs: IDipRun[];
  chainages: number[];

  private static nextId = 0;
  
  constructor(dipData: IDipData) {
    this.runs = dipData.runs.sort((a, b) => a.run - b.run);
    this.chainages = this.getChainages();
  }

  public process(paving: PavingData, config: ReportConfig): void {
    const { compaction } = config;
    
    for (const { run, dips } of this.runs) {
      for (const entry of dips) {
        if (entry.lhs) {
          entry.lhs.expected = applyCompaction(paving.getLevel(entry.chainage, run - 1), compaction);
        }
        
        if (entry.rhs) {
          entry.rhs.expected = applyCompaction(paving.getLevel(entry.chainage, run), compaction);
        }
        
        if (entry.middle && entry.lhs?.expected && entry.rhs?.expected) {
          const expected = (entry.lhs.expected + entry.rhs.expected) / 2;
          
          entry.middle.expected = applyCompaction(expected, compaction);
        }
      }
    }
  }
  
  private getChainages(): number[] {
    const entries = this.runs.flatMap(r => r.dips);
    return entries.map(e => e.chainage).unique();
  }
  
  public getRun(run: number): IDipEntry[] { 
    const chainages = this.chainages;
    const entries = this.runs.find(r => r.run === run)?.dips;
    if (!entries) {
      return [];
    }
    
    for (const ch of chainages) {
      if (ch > entries[entries.length - 1].chainage) {
        entries.push(this.getEmptyEntry(ch, run));
        continue;
      }
      
      for (let i = 0; i < entries.length; ++i) {
        const e = entries[i];
        if (e.chainage === ch) {
          break;
        }
        
        if (e.chainage > ch) { 
          entries.splice(i, 0, this.getEmptyEntry(ch, run));
          break;
        }
      }
    }
    
    return entries;
  }
  
  private getEmptyEntry(chainage: number, run: number, isNew?: boolean): IDipEntry {
    return {
      isNew: isNew || false,
      chainage,
      run,
      description: '',
      timestamp: null,
      picture: null,
      middle: null,
      lhs: { type: DipType.Empty, value: null },
      rhs: { type: DipType.Empty, value: null }
    }
  }
  public static async fromFilesAsync(files: File[]) {
    const dips: IDipEntry[] = [];

    for (const file of files) {
      const text = await file.text();
      const lines = text.split(/\r?\r\n?\n/);

      for (const line of lines) {
        if (this.isValidLine(line)) {
          // is valid data line
          const entry = this.parseDataLine(line);
          if (entry) {
            dips.push(entry);
          }
        }
      }
    }

    const dipData: IDipData = {
      runs: DipData.groupRuns(dips.sort((a, b) => a.chainage - b.chainage))
    };
    
    return new DipData(dipData);
  }

  private static isValidLine(line: string): boolean {
    const match = line.match(/,/g);
    return !!match && match.length >= 7;
  }

  
  /**
   * Processes the 'raw' entries from the .dip file into run groups
   *  - also cleans up the data
   * @param entries the 'raw' entries from the .dip file
   * @returns processed entries organised into runs
   */
  private static groupRuns(entries: IDipEntry[]): IDipRun[] {
    // sort the entries by run   
    const runsMap = entries.groupBy((entry) => entry.run)
    
    // clean up the data
    // 1) find duplicate chainages and combine them if possible, otherwise take the newest
    
    // 2) fill in lhs/rhs values from previous/next run where there was a match on one side  
    const runNumbers = runsMap.keys().toArray().sort();
    let current = 0, prev = 0, next = 0;
    for (let i = 0; i < runNumbers.length; i++) {
        current = runNumbers[i];
        if (i === 0) {
          // handle first run
          prev = 0;
        } 
        else if (i === runNumbers.length - 1) {
          // handle last run
          next = 0;
        }
        else {
          // handle middle runs
          prev = runNumbers[i - 1];
          next = runNumbers[i + 1];
          if (current - prev === 1) {
            for (const entry of runsMap.get(current)!) {
              if (!entry.lhs || entry.lhs.type === DipType.Match) {
                // find the matching entry in the previous run
                const prevEntry = runsMap.get(prev)!.find((e) => e.chainage === entry.chainage);
                if (prevEntry && prevEntry.rhs) {
                  entry.lhs = {
                    ...prevEntry.rhs,
                    type: prevEntry.rhs.type === DipType.Manual ? DipType.Manual : DipType.Match
                  };
                }
              }
            }
          }
          
          if (next - current === 1) {
            for (const entry of runsMap.get(current)!) {
              if (!entry.rhs || entry.rhs.type === DipType.Match) {
                // find the matching entry in the previous run
                const nextEntry = runsMap.get(next)!.find((e) => e.chainage === entry.chainage);
                if (nextEntry && nextEntry.lhs) {
                  entry.rhs = {
                    ...nextEntry.lhs,
                    type: nextEntry.lhs.type === DipType.Manual ? DipType.Manual : DipType.Match
                  };
                }
              }
            }
          }
        }
    }
    
    /**
     * filter/process dips
     */
    for (const run of runsMap.entries()) {
      // super basic duplicate removal
      // TODO: improve this to utilise the timestamp and type properties...
      runsMap.set(run[0], run[1].uniqueBy((e) => e.chainage));
    }
    
    const runs: IDipRun[] = [];
    for (const run of runsMap.entries()) {
      runs.push({
        run: run[0],
        dips: run[1]
      });
    }
    
    return runs;
  }
  
  private static parseDataLine(line: string): IDipEntry {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const split = line.split(',');

    if (split.length === 8) {
      return this.parseOld(split);
    } else {
      return this.parseNew(split);
    }
  }

  private static parseOld(split: string[]): IDipEntry {
    const chainage = Number.parseFloat(split[0]);
    let lhs: number | null = Number.parseInt(split[1]);  
    let rhs: number | null = Number.parseInt(split[2]);
    
    if (lhs === 0) {
      lhs = null;
    }
    
    if (rhs === 0) {
      rhs = null;
    }
    
    const lhsType = DipType[split[3] as keyof typeof DipType];
    const rhsType = DipType[split[4] as keyof typeof DipType];

    const run = this.extractRun(split[5]);

    const description = split[6];
    const picture = split[7];

    return {
      isNew: false,
      chainage,
      lhs: {
        type: lhsType,
        value: lhs
      },
      middle: null,
      rhs: {
        type: rhsType,
        value: rhs
      },
      run,
      description,
      picture,
      timestamp: null
    };
  }

  private static parseNew(split: string[]): IDipEntry {
    const chainage = Number.parseFloat(split[0]);
    const lhs = Number.parseInt(split[1]);
    const rhs = Number.parseInt(split[2]);
    const middle = Number.parseInt(split[3]);
    
    const lhsType = DipType[split[4] as keyof typeof DipType];
    const rhsType = DipType[split[5] as keyof typeof DipType];

    const run = this.extractRun(split[6]);
    const description = split[7];
    const picture = split[8];
    const ts = Number.parseInt(split[9]);

    return {
      isNew: true,
      chainage,
      lhs: {
        type: lhsType,
        value: lhs
      },
      middle: {
        type: DipType.Middle,
        value: middle
      },
      rhs: {
        type: rhsType,
        value: rhs
      },
      run,
      description,
      picture,
      timestamp: new Date(ts)
    };
  }

  private static extractRun(runText: string): number {
    if (!runText.startsWith('Run ') || !runText.includes('-')) {
      return 0;
    }

    return Number.parseInt(runText.substring(4, runText.indexOf('-')));
  }
}
