import { configureStore, createAsyncThunk, DevToolsEnhancerOptions } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import reportReducer, { ReportActionTypes } from '../features/reports/state/reportSlice'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { processReportFiles } from '../features/reports/state/reportActions';
import { reportApiSlice } from '../api/reportApi';

const isDebug = process.env.NODE_ENV !== 'production';

export type RootReducer = {
  reports: typeof reportReducer,
}

const productionDevToolsOptions: DevToolsEnhancerOptions = {
  features: {
    pause: false,
    export: false,
    test: false,
  },

  /**
   * Sanitizes the current state by replacing very large properties (such as the report data rows) with simple
   * overviews of the underlying data. This way it doesn't explode the state viewer when debugging
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateSanitizer: (state: any, _index: any) => {
    const rootState = state as RootState;
    return rootState as any;
  }
};

const developmentDevToolsOptions: DevToolsEnhancerOptions = {
  // put redux-devtools config here...
  features: {
    pause: true,
    test: true,
    persist: true,
    jump: true
  }
};

const getDevToolsOptions = () => {
  return isDebug
    ? developmentDevToolsOptions
    : productionDevToolsOptions;
}

export const store = configureStore({
  reducer: {
    reports: reportReducer,
    [reportApiSlice.reducerPath]: reportApiSlice.reducer
  },
  devTools: getDevToolsOptions(),
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
      // serializableCheck: {
      // 	// ignore Date-related serializable errors 
      // 	ignoredActions: [FilterActions.END_REFRESH, FilterActions.SELECT_START, FilterActions.SELECT_END, HistoryActions.SEARCH_REPORTS],
      // 	ignoredActionPaths: ['payload.latestReportDate'],
      // 	ignoredPaths: ['filterToolbar.startDate', 'filterToolbar.endDate', 'filterToolbar.jobs', 'filterToolbar.job.latestReportDate']
      // }
    });

    return middlewares.concat(reportApiSlice.middleware);//.concat(persistStoreMiddleware);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

type SliceActions<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => infer A ? A : never;
}[keyof T]

export type AllActions = ReturnType<typeof processReportFiles> | SliceActions<ReportActionTypes>;

// Inferred type
export type AppDispatch = typeof store.dispatch;
//export type AppDispatch = Dispatch<AllActions>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppStore: () => typeof store = () => store;

/*
 EXAMPLE Modify usage
 interface OriginalInterface {
  a: string;
  b: boolean;
  c: number;
}

type ModifiedType  = Modify<OriginalInterface , {
  a: number;
  b: number;
}>
 */
export type Modify<T, R> = Omit<T, keyof R> & R;

export type AppThunkApi = BaseThunkAPI<RootState, unknown, AppDispatch>;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  extra: { s: string; n: number }
}>();