import { forwardRef } from 'react';
import { ThemeOptions, createTheme } from '@mui/material/styles';
import { red, blue, green } from '@mui/material/colors';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      success: string;
      danger: string;
      selection: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      success?: string;
      danger?: string;
      selection?: string;
    };
  }
}

// const LinkBehavior1 = forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
//   <RouterLink
//     ref={ref}
//     to='/'
//     {...props}
//     role={undefined}
//   />
// ));

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} role={undefined} {...other} />;
});

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  status: {
    danger: red[700],
    success: green[500],
    selection: blue[700], //'#f50057', // accent-type color
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            backgroundColor: `${theme.status.selection}24`,
            '&:hover': {
              backgroundColor: `${theme.status.selection}2F`,
            },
          },
          ' > .MuiTouchRipple-root': {
            color: theme.status.selection,
          },
        }),
      },
    },
    MuiTouchRipple: {
      styleOverrides: {},
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 500,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'montserrat, sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'montserrat, sans-serif',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: 'rgba(0,0,0,0.54)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontFamily: 'montserrat, sans-serif',
        },
      },
    },
  },
} as ThemeOptions);

export default theme;
